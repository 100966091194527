<template>
  <el-dialog
    title="修改型号"
    :visible.sync="dialogVisible"
    width="700px"
    :close-on-click-modal="false"
    @close="onDialogClose"
    append-to-body
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="180px"
      class="edit-form"
      label-position="right"
      :disabled="submitting"
    >
      <el-form-item label="关联产品" prop="productId" >
        <el-select
          v-model="form.productId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入产品中文名查询"
          :remote-method="remoteMethod"
          class="form-select"
          :loading="productLoading"
        >
          <el-option
            v-for="item in productsOptions"
            :key="item.id"
            :label="
              `${item.name.i18n}-${item.code}`
            "
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格" prop="specificationId">
        <el-select
          v-model="form.specificationId"
          placeholder="请选择规格"
          class="form-select"
        >
          <el-option
            v-for="item in specificationOptions"
            :key="item.id"
            :label="item.description"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="效果" prop="effectId">
        <el-select
          v-model="form.effectId"
          placeholder="请选择效果"
          class="form-select"
        >
          <el-option
            v-for="item in effectOptions"
            :key="item.id"
            :label="item.name ? item.name.i18n:'未知'"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用途(多选)" prop="usageIds">
        <el-select
          v-model="form.usageIds"
          placeholder="请选择用途(多选)"
          class="form-select"
          multiple
        >
          <el-option
            v-for="item in usagesOptions"
            :key="item.id"
            :label="item.name ? item.name.i18n:'未知'"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优等品平方单价(RMB)" prop="rmbPerSquareForSGrade">
        <el-input
          v-model="form.rmbPerSquareForSGrade"
          placeholder="请输入"
          type="number"
        ></el-input>
      </el-form-item>
      <el-form-item label="一等品平方单价(RMB)" prop="rmbPerSquareForAGrade">
        <el-input
          v-model="form.rmbPerSquareForAGrade"
          placeholder="请输入"
          type="number"
        ></el-input>
      </el-form-item>
      <el-form-item label="编号" prop="code">
        <el-input
          v-model="form.code"
          placeholder="请输入"
          type="text"
          @blur="handleExistCheck"
        ></el-input>
      </el-form-item>
      <el-form-item label="次序" prop="seq">
        <el-input-number
          v-model="form.seq"
          label="请输入"
          :min="1"
          style="width:100%"
        ></el-input-number>
      </el-form-item>
      <div v-if="isExists">
        <el-alert
          title="当前修改组合已存在"
          type="warning"
          center
          show-icon
          :closable="false"
        >
        </el-alert>
        <br />
      </div>
      <el-form-item style="text-align: right">
        <!-- <el-button @click="onCancel('form')">关 闭</el-button> -->
        <el-button
          type="primary"
          @click="submitForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  GetProductModelsById,
  PutProductModels,
  GetProductModelsList,
} from "../api";
import { ShowApiError } from "@/request/error";
import options from "../mixins/options";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    sid: {
      type: [String, Number],
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [options],
  data() {
    return {
      currentId: null,
      dialogVisible: false,
      loading: false,
      submitting: false,
      isExists: false,
      form: {},
      rules: {
        productId: [
          { required: true, message: "请选择产品", trigger: "change" },
        ],
        specificationId: [
          { required: true, message: "请选择规格", trigger: "change" },
        ],
        effectId: [
          { required: true, message: "请选择效果", trigger: "change" },
        ],
        usageIds: [
          { required: true, message: "请选择用途", trigger: "change" },
        ],
        rmbPerSquareForSGrade: [
          {
            required: true,
            message: "请输入优等品平方单价",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    sid: {
      handler(value) {
        if (value !== null) {
          this.currentId = Number(value);
          this.getFormData();
        } else {
          this.currentId = null;
        }
      },
      immediate: true,
    },
    show: {
      handler(value) {
        this.dialogVisible = value;
        if (this.dialogVisible) {
          this.initFormOptions();
        }
      },
      immediate: true,
    },
  },
  created(){
    this.initForm();
  },
  methods: {
    handleExistCheck() {
      return new Promise((resolve, reject) => {
        if (isEmprty(this.form.code)) {
          this.isExists = false;
          resolve();
        }
        // 修改模式下，向列表查询相同参数下，是否存在和当前数据id不同的其他数据，如果存在就是重复
        // 如果查询到数据只有一条，且该条数据与当前修改的数据id一致，表示不是重复的
        GetProductModelsList({
          code: this.form.code,
        })
          .then((res) => {
            let { content } = res.data;
            if (content.length <= 0) {
              this.isExists = false;
              resolve();
            } else {
              let results = content.filter((d) => d.id !== this.currentId);
              if (results.length > 0) {
                this.isExists = true;
                reject();
              } else {
                this.isExists = false;
                resolve();
              }
            }
          })
          .catch(() => {
            resolve();
          });
      });
    },
    submitForm(formName) {
      this.handleExistCheck().then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitting = true;
            PutProductModels([
              {
                id: this.currentId,
                ...this.form,
              },
            ])
              .then(() => {
                this.$message.success("修改成功!");
                this.initForm();
                this.getFormData();
                this.emitSucccess();
              })
              .catch((err) => {
                ShowApiError("保存失败", err);
              })
              .finally(() => {
                this.submitting = false;
              });
          } else {
            this.$message.warning("表单有误，请检查");
            return false;
          }
        });
      });
    },
    getFormData() {
      this.loading = true;
      GetProductModelsById(this.currentId)
        .then((res) => {
          let {
            productId,
            specificationId,
            effectId,
            rmbPerSquareForSGrade,
            rmbPerSquareForAGrade,
            usages,
            code,
            seq,
          } = res.data;
          this.form = {
            productId: productId,
            specificationId: specificationId,
            effectId: effectId,
            rmbPerSquareForSGrade: rmbPerSquareForSGrade,
            rmbPerSquareForAGrade: rmbPerSquareForAGrade,
            usageIds: usages.map((item) => item.id),
            code: code,
            seq: seq,
          };
          this.loading = false;
        })

        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单数据失败",err);
        });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    initForm() {
      this.form = {
        productId: null,
        specificationId: null,
        effectId: null,
        rmbPerSquareForSGrade: null,
        rmbPerSquareForAGrade: null,
        usageIds: [],
        code: null,
        seq: 0,
      };
    },
    onDialogClose() {
      this.dialogVisible = false;
      this.isExists = false;
      this.$emit("close", this.dialogVisible);
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-form {
  max-width: 100%;
  padding: 0 20px;
  .form-select {
    width: 100%;
  }
}
</style>
