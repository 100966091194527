<template>
  <el-dialog
    title="型号编辑"
    :visible.sync="dialogVisible"
    width="80%"
    :close-on-click-modal="false"
    @close="onDialogClose"
    append-to-body
  >
    <el-alert
      title="次序的值越大该条数据越靠前"
      type="info"
      center
      :closable="false"
      show-icon
    >
    </el-alert>
    <br />
    <el-form
      :model="form"
      ref="form"
      label-width="100px"
      class="multiple-ruleForm"
      label-position="top"
      size="small"
      :disabled="submitting"
    >
      <el-row :gutter="10">
        <el-col
          :sm="24"
          :md="24"
          v-for="(item, index) in form.items"
          :key="item.key"
        >
          <el-card
            class="box-card"
            shadow="never"
            :body-style="{
              padding: ' 0 10px',
            }"
          >
            <div slot="header" class="clearfix">
              条目{{ index + 1 }}
              <el-button
                class="text-danger"
                style="float: right; padding: 3px 0"
                type="text"
                @click="onDeleteRow(index)"
                :disabled="submitting || form.items.length <= 1"
                >删除</el-button
              >
            </div>
            <el-row :gutter="10">
              <el-col :sm="24" :md="8">
                <el-form-item
                  label-position="left"
                  label="产品"
                  :prop="'items.' + index + '.productId'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择产品',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="item.productId"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入产品中文名查询"
                    :remote-method="remoteMethod"
                    class="form-select"
                    :loading="productLoading"
                  >
                    <el-option
                      v-for="item in productsOptions"
                      :key="item.id"
                      :label="`${item.name.i18n}-${item.code}`"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="4">
                <el-form-item
                  label-position="left"
                  label="规格"
                  :prop="'items.' + index + '.specificationId'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择规格',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="item.specificationId"
                    placeholder="请选择规格"
                    class="form-select"
                  >
                    <el-option
                      v-for="item in specificationOptions"
                      :key="item.id"
                      :label="item.description"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6">
                <el-form-item
                  label-position="left"
                  label="效果"
                  :prop="'items.' + index + '.effectId'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择效果',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="item.effectId"
                    placeholder="请选择效果"
                    class="form-select"
                  >
                    <el-option
                      v-for="item in effectOptions"
                      :key="item.id"
                      :label="item.name ? item.name.i18n : '未知'"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6">
                <el-form-item
                  label-position="left"
                  label="用途(多选)"
                  :prop="'items.' + index + '.usageIds'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择用途',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="item.usageIds"
                    placeholder="请选择用途"
                    class="form-select"
                    multiple
                  >
                    <el-option
                      v-for="item in usagesOptions"
                      :key="item.id"
                      :label="item.name ? item.name.i18n : '未知'"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="4">
                <el-form-item
                  label="优等品平方单价(RMB)"
                  :prop="'items.' + index + '.rmbPerSquareForSGrade'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入优等品平方单价',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    :disabled="submitting"
                    v-model.trim="item.rmbPerSquareForSGrade"
                    clearable
                    :ref="`input${index}`"
                    placeholder="请输入"
                    type="number"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="4">
                <el-form-item
                  label="一等品平方单价(RMB)"
                  :prop="'items.' + index + '.rmbPerSquareForAGrade'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入一等品平方单价',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    :disabled="submitting"
                    v-model.trim="item.rmbPerSquareForAGrade"
                    clearable
                    :ref="`input${index}`"
                    placeholder="请输入"
                    type="number"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6">
                <el-form-item
                  label="编号"
                  :prop="'items.' + index + '.code'"
                  :rules="[
                    { required: false, message: '请输入编号', trigger: 'blur' },
                  ]"
                >
                  <el-input
                    :disabled="submitting"
                    v-model.trim="item.code"
                    clearable
                    :ref="`input${index}`"
                    placeholder="请输入"
                    type="text"
                    @blur="handleExistCheck(item, index)"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="4">
                <!-- (数值越大越靠前) -->
                <el-form-item
                  label="次序"
                  :prop="'items.' + index + '.seq'"
                  :rules="[
                    { required: false, message: '请输入次序', trigger: 'blur' },
                  ]"
                >
                  <el-input
                    :disabled="submitting"
                    v-model.trim="item.seq"
                    clearable
                    :ref="`input${index}`"
                    placeholder="请输入"
                    type="number"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="4">
                <el-form-item
                  label="是否已存在"
                  :prop="'items.' + index + '.isExists'"
                >
                  <div v-if="item.isChecking">
                    <i class="el-icon-loading"></i>
                    查重中...
                  </div>
                  <div v-else>
                    <span class="text-danger" v-if="item.isExists"
                      >该编号已存在</span
                    >
                    <span class="text-success" v-else>可添加</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card></el-col
        >
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleReset" :disabled="submitting">重 置</el-button>
      <el-button @click="addRow" :disabled="submitting">新增一行</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >{{ submitting ? "提交中..." : "提交创建" }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { createUniqueString } from "@/utils/common";
import { PostProductModels, GetProductModelsCheck } from "../api";
import { ShowApiError } from "@/request/error";
import options from "../mixins/options";
import { isEmprty } from "@/utils/validate";
import _lodash from "lodash";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: [String, Number],
      default: null,
    },
  },
  mixins: [options],
  data() {
    return {
      currentProductId: null,
      submitting: false,
      focusIndex: 0,
      dialogVisible: false,
      form: {
        items: [],
      },
    };
  },

  watch: {
    productId: {
      handler(val) {
        this.currentProductId = val;
      },
      immediate: true,
    },
    show: {
      handler(value) {
        this.dialogVisible = value;
        if (this.dialogVisible) {
          this.initFormOptions();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    createUniqueString,
    async handleExistCheck(item, index) {
      if (isEmprty(item.code)) {
        this.form.items[index].isExists = false;
        return;
      }
      try {
        item.isChecking = true;
        let { data } = await GetProductModelsCheck({
          code: item.code,
        });
        this.form.items[index].isExists = data;
      } finally {
        item.isChecking = false;
      }
    },

    buildFormData() {
      let datas = _lodash.cloneDeep(this.form.items);
      datas
        .filter((item) => item.isExists == false)
        .map((item) => {
          delete item.key;
          delete item.isExists;
          delete item.isChecking;
          if (item.code == "") {
            item.code = null;
          }
          if (!isEmprty(item.rmbPerSquareForSGrade)) {
            item.rmbPerSquareForSGrade = Number(item.rmbPerSquareForSGrade);
          }
          if (!isEmprty(item.rmbPerSquareForAGrade)) {
            item.rmbPerSquareForAGrade = Number(item.rmbPerSquareForAGrade);
          }
          return item;
        });
      return datas;
    },

    validForm() {
      return new Promise((resolve, reject) => {
        console.log("validForm:this.form.items", this.form.items);
        let res = this.form.items.every((item) => item.isExists == false);
        if (res) {
          resolve();
        } else {
          this.$confirm(
            "提交的表单中存在重复的组合，是否需要自动去除并提交?",
            "重复提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              resolve();
              this.$message.success("表单已重置");
            })
            .catch(() => {
              this.$message.info("已取消");
              reject();
            });
        }
      });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.validForm().then(() => {
            this.submitting = true;
            PostProductModels(this.buildFormData())
              .then((res) => {
                console.log("新增成功", res);
                this.submitting = false;
                this.$message.success("新增成功");
                this.$emit("success");
                this.initForm();
              })
              .catch((err) => {
                console.log("新增失败", err);
                this.submitting = false;
                ShowApiError("新增失败", err);
              });
          });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    buildFormItem(params = {}) {
      let formItem = {
        productId: this.currentProductId,
        specificationId: null,
        effectId: null,
        rmbPerSquareForSGrade: null,
        rmbPerSquareForAGrade: null,
        usageIds: [],
        code: null,
        isExists: false,
        isChecking: false,
        seq: 1,
        key: this.createUniqueString(),
      };
      if (params && Object.keys(params).length > 0) {
        for (const key in formItem) {
          if (Object.hasOwnProperty.call(params, key)) {
            formItem[key] = params[key];
          }
        }
      }
      return formItem;
    },
    initForm() {
      let item = this.buildFormItem();
      this.form = {
        items: [{ ...item }],
      };
    },
    addRow(inputObj = "") {
      this.form.items.push({
        ...this.buildFormItem({
          seq: this.form.items.length + 1,
        }),
      });
      this.$nextTick(() => {
        if (this.$refs[inputObj]) {
          this.$refs[inputObj][0].focus();
        }
      });
    },
    onDeleteRow(index) {
      if (this.form.items.length > 1) {
        this.form.items.splice(index, 1);
      }
    },
    onDialogClose() {
      this.dialogVisible = false;
      this.$emit("close", this.dialogVisible);
    },
    handleReset() {
      this.$confirm("操作不可撤回，确定要重置表单吗?", "重置提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.initForm();
          this.$message.success("表单已重置");
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select {
  width: 100%;
  height: 32px;
  overflow: hidden;
}
.icon-img {
  width: 100px;
  height: 100px;
}
.form-tips {
  font-size: 13px;
  text-align: left;
  padding: 10px;
}
.box-card {
  margin-bottom: 10px;
}
.multiple-ruleForm {
  padding: 0 10px;
}
</style>
