import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["specificationOptions", "effectOptions", "usagesOptions", "productsOptions", "productLoading"]),
  },
  methods: {
    ...mapActions({
      refreshSpecificationsOptions: "product/refreshSpecificationsOptions",
      refreshEffectsOptions: "product/refreshEffectsOptions",
      refreshUsagesOptions: "product/refreshUsagesOptions",
      refreshProductOptions: "product/refreshProductOptions",

    }),
    initFormOptions() {
      this.refreshSpecificationsOptions();
      this.refreshEffectsOptions();
      this.refreshUsagesOptions();
      this.refreshProductOptions();
    },
    remoteMethod(query) {
      this.refreshProductOptions({
        nameCn: query
      })
    }
  },
}