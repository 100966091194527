<template>
  <section class="filter-wrap">
    <BasicFilterWrap
      :moreFilter="showMoreFilter"
      @confrim="onSearch"
      @reset="onResetFilter"
      @more="showMoreFilter = !showMoreFilter"
    >
      <el-form ref="form" :model="tableFilters" label-width="auto">
        <el-row :gutter="20">
          <el-col :sm="24" :md="12" :lg="8" >
            <el-form-item label="产品">
              <el-select
                v-model="tableFilters.productId"
                filterable
                remote
                reserve-keyword
                placeholder="请输入产品中文名查询"
                :remote-method="remoteMethod"
                class="form-select"
                multiple
                :loading="productLoading"
                clearable
              >
                <el-option
                  v-for="item in productsOptions"
                  :key="item.id"
                  :label="`${item.name.i18n}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="规格">
              <el-select
                v-model="tableFilters.specificationId"
                multiple
                placeholder="请选择规格"
                class="form-select"
                clearable
              >
                <el-option
                  v-for="item in specificationOptions"
                  :key="item.id"
                  :label="item.description"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="效果">
              <el-select
                v-model="tableFilters.effectId"
                multiple
                placeholder="请选择效果"
                class="form-select"
                clearable
              >
                <el-option
                  v-for="item in effectOptions"
                  :key="item.id"
                  :label="item.name.i18n"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-collapse-transition>
          <el-row :gutter="10" v-show="showMoreFilter">
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="用途">
                <el-select
                  v-model="tableFilters.usagesId"
                  multiple
                  placeholder="请选择用途"
                  class="form-select"
                  clearable
                >
                  <el-option
                    v-for="item in usagesOptions"
                    :key="item.id"
                    :label="item.name.i18n "
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建日期">
                <el-date-picker
                  v-model="tableFilters.createdAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新日期">
                <el-date-picker
                  v-model="tableFilters.lastModifiedAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新人">
                <BasicAsyncSelect
                  v-model="tableFilters.lastModifiedById"
                  :asyncObj="{
                    dataFun: GetUsersList,
                  }"
                />
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建人">
                <BasicAsyncSelect
                  v-model="tableFilters.createdById"
                  :asyncObj="{
                    dataFun: GetUsersList,
                  }"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-transition>
      </el-form>
    </BasicFilterWrap>
  </section>
</template>

<script>
import BasicAsyncSelect from "@/components/BasicAsyncSelect";
import { onClearFilter } from "@/utils/table";
import BasicFilterWrap from "@/components/BasicFilterWrap";
import { GetUsersList } from "@/views/users/api";
import options from "../mixins/options";
export default {
  components: {
    BasicAsyncSelect,
    BasicFilterWrap,
  },
  mixins: [options],
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        createdAt: null,
        lastModifiedAt: null,
        createdById: null,
        lastModifiedById: null,
        specificationId: [],
        effectId: [],
        usagesId: [],
        productId: [],
      },
    };
  },

  created() {
    this.initFormOptions();
  },
  methods: {
    GetUsersList,
    onSearch() {
      this.$emit("change", this.tableFilters);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.initFormOptions();
      this.$emit("change", this.tableFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select {
  width: 100%;
}
.filter-wrap {
  box-sizing: border-box;
  margin: 10px 10px 0 10px;
  padding: 10px;
  background-color: #fff;
}
</style>
