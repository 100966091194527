var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"型号编辑","visible":_vm.dialogVisible,"width":"80%","close-on-click-modal":false,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.onDialogClose}},[_c('el-alert',{attrs:{"title":"次序的值越大该条数据越靠前","type":"info","center":"","closable":false,"show-icon":""}}),_c('br'),_c('el-form',{ref:"form",staticClass:"multiple-ruleForm",attrs:{"model":_vm.form,"label-width":"100px","label-position":"top","size":"small","disabled":_vm.submitting}},[_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.form.items),function(item,index){return _c('el-col',{key:item.key,attrs:{"sm":24,"md":24}},[_c('el-card',{staticClass:"box-card",attrs:{"shadow":"never","body-style":{
            padding: ' 0 10px',
          }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_vm._v(" 条目"+_vm._s(index + 1)+" "),_c('el-button',{staticClass:"text-danger",staticStyle:{"float":"right","padding":"3px 0"},attrs:{"type":"text","disabled":_vm.submitting || _vm.form.items.length <= 1},on:{"click":function($event){return _vm.onDeleteRow(index)}}},[_vm._v("删除")])],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"sm":24,"md":8}},[_c('el-form-item',{attrs:{"label-position":"left","label":"产品","prop":'items.' + index + '.productId',"rules":[
                  {
                    required: true,
                    message: '请选择产品',
                    trigger: 'change',
                  },
                ]}},[_c('el-select',{staticClass:"form-select",attrs:{"filterable":"","remote":"","reserve-keyword":"","placeholder":"请输入产品中文名查询","remote-method":_vm.remoteMethod,"loading":_vm.productLoading},model:{value:(item.productId),callback:function ($$v) {_vm.$set(item, "productId", $$v)},expression:"item.productId"}},_vm._l((_vm.productsOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":`${item.name.i18n}-${item.code}`,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"sm":24,"md":4}},[_c('el-form-item',{attrs:{"label-position":"left","label":"规格","prop":'items.' + index + '.specificationId',"rules":[
                  {
                    required: true,
                    message: '请选择规格',
                    trigger: 'change',
                  },
                ]}},[_c('el-select',{staticClass:"form-select",attrs:{"placeholder":"请选择规格"},model:{value:(item.specificationId),callback:function ($$v) {_vm.$set(item, "specificationId", $$v)},expression:"item.specificationId"}},_vm._l((_vm.specificationOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.description,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label-position":"left","label":"效果","prop":'items.' + index + '.effectId',"rules":[
                  {
                    required: true,
                    message: '请选择效果',
                    trigger: 'change',
                  },
                ]}},[_c('el-select',{staticClass:"form-select",attrs:{"placeholder":"请选择效果"},model:{value:(item.effectId),callback:function ($$v) {_vm.$set(item, "effectId", $$v)},expression:"item.effectId"}},_vm._l((_vm.effectOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name ? item.name.i18n : '未知',"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label-position":"left","label":"用途(多选)","prop":'items.' + index + '.usageIds',"rules":[
                  {
                    required: true,
                    message: '请选择用途',
                    trigger: 'change',
                  },
                ]}},[_c('el-select',{staticClass:"form-select",attrs:{"placeholder":"请选择用途","multiple":""},model:{value:(item.usageIds),callback:function ($$v) {_vm.$set(item, "usageIds", $$v)},expression:"item.usageIds"}},_vm._l((_vm.usagesOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name ? item.name.i18n : '未知',"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"sm":24,"md":4}},[_c('el-form-item',{attrs:{"label":"优等品平方单价(RMB)","prop":'items.' + index + '.rmbPerSquareForSGrade',"rules":[
                  {
                    required: true,
                    message: '请输入优等品平方单价',
                    trigger: 'blur',
                  },
                ]}},[_c('el-input',{ref:`input${index}`,refInFor:true,attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入","type":"number"},model:{value:(item.rmbPerSquareForSGrade),callback:function ($$v) {_vm.$set(item, "rmbPerSquareForSGrade", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.rmbPerSquareForSGrade"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":4}},[_c('el-form-item',{attrs:{"label":"一等品平方单价(RMB)","prop":'items.' + index + '.rmbPerSquareForAGrade',"rules":[
                  {
                    required: true,
                    message: '请输入一等品平方单价',
                    trigger: 'blur',
                  },
                ]}},[_c('el-input',{ref:`input${index}`,refInFor:true,attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入","type":"number"},model:{value:(item.rmbPerSquareForAGrade),callback:function ($$v) {_vm.$set(item, "rmbPerSquareForAGrade", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.rmbPerSquareForAGrade"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label":"编号","prop":'items.' + index + '.code',"rules":[
                  { required: false, message: '请输入编号', trigger: 'blur' },
                ]}},[_c('el-input',{ref:`input${index}`,refInFor:true,attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入","type":"text"},on:{"blur":function($event){return _vm.handleExistCheck(item, index)}},model:{value:(item.code),callback:function ($$v) {_vm.$set(item, "code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.code"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":4}},[_c('el-form-item',{attrs:{"label":"次序","prop":'items.' + index + '.seq',"rules":[
                  { required: false, message: '请输入次序', trigger: 'blur' },
                ]}},[_c('el-input',{ref:`input${index}`,refInFor:true,attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入","type":"number"},model:{value:(item.seq),callback:function ($$v) {_vm.$set(item, "seq", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.seq"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":4}},[_c('el-form-item',{attrs:{"label":"是否已存在","prop":'items.' + index + '.isExists'}},[(item.isChecking)?_c('div',[_c('i',{staticClass:"el-icon-loading"}),_vm._v(" 查重中... ")]):_c('div',[(item.isExists)?_c('span',{staticClass:"text-danger"},[_vm._v("该编号已存在")]):_c('span',{staticClass:"text-success"},[_vm._v("可添加")])])])],1)],1)],1)],1)}),1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.handleReset}},[_vm._v("重 置")]),_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.addRow}},[_vm._v("新增一行")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v(_vm._s(_vm.submitting ? "提交中..." : "提交创建"))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }